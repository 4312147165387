<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title class="my-0 py-0">
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-3"
                            dark
                            color="primary"
                            @click="createItem"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.classifiers.gameTypes.add') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.classifiers.gameTypes.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :sort-by="sortby"
                    :multi-sort="true"
                    :search="search"
                    :custom-filter="filterTableItems"
                    @click:row="infoItem"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.description="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template v-slot:item.created_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value) | highlight(search)" />
                    </template>
                    <template #item.updated_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value) | highlight(search)" />
                    </template>

                    <!--                    <template v-slot:item.role_id="{ item }">
    {{ itemsRoles.find((r) => r.id_role == item.role_id).name }}
</template>-->

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click.stop="infoItem(item)"
                                    v-on="on"
                                >
                                    mdi-information
                                </v-icon>
                            </template>

                            <span>{{ $t('common.listElement') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click.stop="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    @click.stop="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchData"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <classifier-create
                v-if="dialog"
                :dialog="dialog"
                :item="itemSelected"
                messages-id="gameTypes"
                :url-main="urlMain"
                @update:dialog="dialog = $event"
                @update:table="fetchData"
            />

            <confirm-dialog
                ref="confirm"
            />
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
// import RoleCreate from '@/components/admin/roles/rolesCreate'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import { debounce } from "debounce";
import { mapGetters } from 'vuex'
import listAdminMixin from "@/mixins/listAdminMixin";

import classifierCreate from "@/components/admin/games/classifiers/classifierCreate";

export default {
    components: { classifierCreate, ConfirmDialog },
    mixins: [listAdminMixin],
    data: function () {
        return {
            urlMain: 'admin/games/types',
            /* dialog: false, */
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.classifiers.name'),
                    value: 'name'
                },
                {
                    text: this.$t('admin.classifiers.description'),
                    value: 'description'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'created_at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'updated_at'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ]
        }
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        initialize($data) {
            this.items = $data.gameTypes ?? [];

            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0;
        },
        infoItem(item) {
            this.$router.push(`/app/admin/games?type=${item.id}`);
        }
    }
}
</script>
